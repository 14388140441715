import ClipLoader from "react-spinners/ClipLoader";
import ModalJobApply from "../Modals/ModalJobApply";
import ModalJobReport from "../Modals/ModalJobReport";
import ModalShare from "../Modals/ModalShare";
import ModalJobClose from "../Modals/ModalJobClose";
import emptyResult from "@/Assets/svgs/empty_result.svg";
import { CURRENCY } from "@/constants/storageConstants";
import { redirectBack } from "@/utils/redirectUtil";
import arrowLeft from "@/Assets/svgs/arrow-left.svg";
import {
  isMyJob,
  isClosedJob,
  isDraftJob,
  isAppliedJob,
  isSavedJob,
  isReportedJob,
  maskEmailPhone,
  isOpenJob,
} from "@/utils/job";

import * as toast from "@/wrapper/toast";
import * as errors from '@/constants/stringConstants';

import { isCandidate, isLoggedIn } from "@/utils/authUtil";
import { createSlugFromString, valueOrDefault } from "@/utils/stringUtils";
import { getCandidateExperienceText } from "@/services/common";
import ModalInviteReferralUser from "../Modals/ModalInviteReferralUser";
import { textCapitalise } from "@/utils/stringUtils";
import ModalShowReferralData from "../Modals/ModalShowReferralData";
import referredJobImg from "@/Assets/svgs/referredJob.svg";
import { getSkillsString, highlightText } from "../../utils/stringUtils";
import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";
import Link from "../_widgets/Link/Link";
import { useRef } from "react";
import AppModal from "../Modals/AppModal";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import { isFeaturedJob } from "../../utils/job";
import { addCandidateToSaveLater, getBarColor, getPercentage } from "../../services/common";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";
import Loader from "../_widgets/Loader/Loader";
import CustomTooltip from "../../utils/CustomTooltip";
import { OverlayTrigger } from "react-bootstrap";
import mapPin from "@/Assets/svgs/map-pin.svg";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

function JobDetailsLayouts({ jobDetails, color, override, showModalApply, setShowModalApply, jobDetail, showModalReport, setShowModalReport, showModalClose, setShowModalClose, showModalShare, setShowModalShare, showModalReferral, setShowModalReferral, showModalReferralData, setShowModalReferralData, keyword, loading, referralData, currentUser, navigate, commonServices, referAFriend, openApplyModal, closeJob, endJobReferral, reNewJob, saveJob, openReportModal, duplicateJob, addProfileTracking, job_id, changeApplicantStatus, openShareModal, showFeatureJobModal, setShowFeatureJobModal, submitFeatureJob, featureJobDuration, setFeatureJobDuration, buttonLoading, masterData, disableFeatureJobButton, setLoading, selectedCriterias, setSelectedCriterias, showSimilarCandidatesModal, setShowSimilarCandidatesModal, isSimilarCriteriaChecked, showSimilarSearch,
}) {
  const ref = useRef(null);

  const handleClick = (tab = "") => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    if (tab !== "") {
      document.getElementById("list-" + tab + "-list").click();
    }
  };

  const featureJobOptions = masterData?.featured_days?.map(featuredDay => ({
    name: featuredDay.name,
    id: parseInt(featuredDay.value),
  })) || [];

  return jobDetails ? (
    <div className="apply_page tt-container">
      {loading ? (
       <Loader />
      ) : null}
      <div className="sec_head">
        <Link
          type={"a"}
          className={"hand-hover"}
          onClick={() => redirectBack()}
          text={`<img className="back-arrow" src=${arrowLeft}></img>`}
        />
        <img className="logo-microsoft" src={jobDetails.company_logo} alt="" />
      </div>
      <div className="sec_one">
        <ModalJobApply
          showModal={showModalApply}
          setShowModal={setShowModalApply}
          jobDetail={jobDetail}
          job_name={jobDetails.title}
          job_id={jobDetails.eid}
        />
        <ModalJobReport
          showModal={showModalReport}
          setShowModal={setShowModalReport}
          jobDetail={jobDetail}
          job_name={jobDetails.title}
          job_id={jobDetails.eid}
        />
        <ModalJobClose
          showModal={showModalClose}
          setShowModal={setShowModalClose}
          jobDetail={jobDetail}
          job_name={jobDetails.title}
          job_id={jobDetails.eid}
        />
        <ModalShare
          showModal={showModalShare}
          setShowModal={setShowModalShare}
          title={jobDetails.title}
          url={window.location.href}
        />
        <ModalInviteReferralUser
          showModal={showModalReferral}
          setShowModal={setShowModalReferral}
          id={referralData?.id}
          url={referralData?.url}
          getReferrals={jobDetail}
          audience={textCapitalise(referralData?.target_audience)}
        />
        <ModalShowReferralData
          showModal={showModalReferralData}
          setShowModal={setShowModalReferralData}
          jobData={jobDetails}
          referralData={referralData}
          showModalReferral={showModalReferral}
          setShowModalReferral={setShowModalReferral}
        />

        {/* Feature Job Modal */}
        <AppModal
          title='Feature Job on Search Pages'
          isOpen={showFeatureJobModal}
          isFooter={true}
          loading={buttonLoading}
          submitText='Feature'
          submit={() => submitFeatureJob(jobDetails.eid, featureJobDuration)}
          closeModal={() => {
            setShowFeatureJobModal(false);
            setFeatureJobDuration('');
          }}
          disableSubmit={disableFeatureJobButton()}
        >
          <Dropdown
            label='Feature Job Duration'
            name="featureJobDuration"
            id="featureJobDuration"
            isRequired={true}
            showDefaultOption={true}
            onChange={e => setFeatureJobDuration(e.target.value)}
            select={featureJobOptions}
          />

          <div className='cash-summary my-4'>
            {disableFeatureJobButton() ?
              <div>
                <div className="col-12">
                  <h6 className='available-cash'>Total Available TT Cash: {commonServices.currencyFormatter(jobDetails?.user_remaining_amount)}</h6>
                </div>

                <div className='d-flex align-items-center gap-2'>
                  <div className='w-auto unavailable-cash'>
                    <h6>You need {commonServices.currencyFormatter(featureJobDuration * masterData.system_config.searchpage_feature_job_slot_price.value)} TT cash for this Referral Boost</h6>
                  </div>

                  <div>
                    <a href="?action=buy" style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Buy/Allocate</a>
                    <a href={window.location.href} style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Refresh</a>
                  </div>
                </div>
                <br /><br />
                <h6>For buying Featured Home Page Slots, please <a href="/company/users?tab=featured-jobs" target="_blank">Click Here</a></h6>
              </div>
              :
              <>
                <div className="row">
                  <div className="col-12">
                    <h6 className='available-cash'>Total Available TT Cash: {commonServices.currencyFormatter(jobDetails?.user_remaining_amount)}</h6>
                  </div>
                  <div className="col-12">
                    {featureJobDuration ? <h6 className='available-cash'>TT Cash you will spend in this Referral Boost: {commonServices.currencyFormatter(featureJobDuration * masterData?.system_config.searchpage_feature_job_slot_price.value)}&nbsp;  </h6> : null}
                  </div>
                </div>
                <br /><br />
                <h6>For buying Featured Home Page Slots, please <a href="/company/users?tab=featured-jobs" target="_blank">Click Here</a></h6>
              </>
            }
          </div>
        </AppModal>

        {/* View-similar Candidates Modal */}
        <ModalShowSimilarCandidates
          showSimilarCandidatesModal={showSimilarCandidatesModal}
          setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
          showSimilarSearch={showSimilarSearch}
          setSelectedCriterias={setSelectedCriterias}
          selectedCriterias={selectedCriterias}
          isSimilarCriteriaChecked={isSimilarCriteriaChecked}
        />

        <div className="d-flex w-100 align-items-start justify-content-between mb-2 flex-nowrap">
          <div className="sec_one_one">
            <Text
              type="h2"
              className="logo-content truncate-3"
              title={jobDetails?.title}
              text={
                highlightText(keyword, jobDetails.title)}
            />
            {isMyJob(jobDetails) && !isDraftJob(jobDetails) ? (
              <p className="logo-para">
                <Link
                  type={"a"}
                  className={"hand-hover green-text"}
                  style={{ color: "#000", textDecoration: "none" }}
                  aHref={undefined}
                  onClick={handleClick}
                  text={`Applicants (${jobDetails.total_applicants})`}
                />

                <i class="bi bi-dot"></i>
                <Link
                  type={"a"}
                  className={"hand-hover green-text"}
                  style={{ color: "#000", textDecoration: "none" }}
                  aHref={undefined}
                  onClick={() => handleClick("shortlisted")}
                  text={`Shortlisted (${jobDetails?.applicants?.shortlisted.length})`}
                />
                <i class="bi bi-dot"></i>
                <Link
                  type={"a"}
                  className={"hand-hover green-text"}
                  style={{ color: "#000", textDecoration: "none" }}
                  href={undefined}
                  onClick={() => handleClick("rejected")}
                  text={`Rejected (${jobDetails?.applicants?.rejected.length})`}
                />
                <i class="bi bi-dot"></i>
                <Link
                  type={"a"}
                  className={"hand-hover green-text"}
                  style={{ color: "#000", textDecoration: "none" }}
                  href={undefined}
                  onClick={() => handleClick("applications")}
                  text={`New (${jobDetails?.applicants?.applications.length})`}
                />
              </p>
            ) : null}
            {currentUser && currentUser.company_id === jobDetails.company_id ? (
              <p>
                {" "}
                Reference Number:{" "}
                {jobDetails.reference_number
                  ? jobDetails.reference_number
                  : "N/A"}{" "}
              </p>
            ) : (
              ""
            )}
            <p className="logo-para">
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightText(keyword, jobDetails.company_name),
                }}></span>
              {jobDetails.company_location ? <i class="bi bi-dot"></i> : ""}{" "}
              {jobDetails.company_location ? jobDetails.company_location : ""}
              <i class="bi bi-dot"></i>{" "}
              {isDraftJob(jobDetails) ? "Drafted" : "Posted"}&nbsp;
              {jobDetails.posted_date}
              <i class="bi bi-dot"></i> Posted By &nbsp;
              {jobDetails?.user_details?.full_name}
              <i class="bi bi-dot"></i> Updated &nbsp; {jobDetails?.updated_date}
            </p>
          </div>

          <div className="sec_one_two">
            {isMyJob(jobDetails) && !isClosedJob(jobDetails) ? (
              <>
                <Button
                  className="btn btn-apply"
                  buttonType={"primary"}
                  style={{ fontSize: "1.2em", width: "100px", display: "flex", alignItems: "center", justifyContent: "space-around" }}
                  onClick={() => {
                    navigate("/job/edit/" + jobDetails.eid);
                  }}
                  buttonIcon={
                    <img
                      onError={commonServices.imgError}
                      className="edit"
                      src="../../.././Assets/svgs/edit-job.svg"
                      alt=""
                    />
                  }
                  text={"Edit"}
                />
              </>
            ) : isCandidate() ? (
              isClosedJob(jobDetails) ? (
                <Button
                  buttonType={"secondary"}
                  disabled
                  style={{ marginLeft: "10px" }}
                  text={"Closed"}
                />
              ) : isAppliedJob(jobDetails) ? (
                <>
                  <Button buttonType="secondary" disabled text={"Applied"} />
                  {jobDetails.referral && jobDetails.referral.length > 0 ? (
                    <Button
                      buttonType="secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => referAFriend()}
                      text={"Refer a friend"}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <Button
                    buttonType="primary"
                    onClick={() => {
                      openApplyModal();
                    }}
                    text={"Apply Now"}
                  />
                  {jobDetails.referral && jobDetails.referral.length > 0 ? (
                    <Button
                      buttonType="secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => referAFriend()}
                      text={"Refer a friend"}
                    />
                  ) : null}
                </>
              )
            ) : !isLoggedIn() ? (
              <Button
                buttonType="primary"
                onClick={() => {
                  navigate(
                    "/login?redirect=/job/details/" +
                    jobDetails.eid +
                    "/" +
                    createSlugFromString(jobDetails.title)
                  );
                }}
                text={"Login to Apply"}
              />
            ) : (
              ""
            )}
            {isMyJob(jobDetails) || isOpenJob(jobDetails) ? (
              <div className="dropdown">

                <button
                  style={{
                    border: "solid 1px var(--gray5)",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "transparent",
                    borderRadius: "8px",
                    marginLeft: "10px",
                    paddingLeft: "0px",
                  }}
                  className="dropdown-toggle"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </button>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  {!isClosedJob(jobDetails) ? (
                    <>
                      {!isClosedJob(jobDetails) && !isDraftJob(jobDetails) ? (
                        <li
                          onClick={() => {
                            openShareModal();
                          }}>
                          <Link
                            type={"a"}
                            className={"dropdown-item"}
                            dataBsToggle={"collapse"}
                            dataBsTarget={".navbar-collapse.show"}
                            ariaControls={"navbarSupportedContent"}
                            ariaExpanded={"false"}
                            ariaLabel={"Toggle navigation"}
                            ariaCurrent={"page"}
                            text={"Share"}
                          />
                        </li>
                      ) : null}
                      {isMyJob(jobDetails) && !isClosedJob(jobDetails) ? (
                        <>
                          {isDraftJob(jobDetails) ? (
                            <li
                              onClick={() => {
                                closeJob("published");
                              }}>
                              <Link
                                type={"a"}
                                className={"dropdown-item"}
                                dataBsToggle={"collapse"}
                                dataBsTarget={".navbar-collapse.show"}
                                ariaControls={"navbarSupportedContent"}
                                ariaExpanded={"false"}
                                ariaLabel={"Toggle navigation"}
                                ariaCurrent={"page"}
                                text={"Publish Job"}
                              />
                            </li>
                          ) : (
                            <>
                              {jobDetails.referral &&
                                jobDetails.referral.length > 0 ? (
                                <li
                                  onClick={() => {
                                    endJobReferral(jobDetails.referral[0].id);
                                  }}>
                                  <Link
                                    type={"a"}
                                    className={"dropdown-item"}
                                    ariaControls={"navbarSupportedContent"}
                                    ariaExpanded={"false"}
                                    ariaLabel={"Toggle navigation"}
                                    ariaCurrent="page"
                                    text={"End Referral"}
                                  />
                                </li>
                              ) : (
                                <li
                                  onClick={() => {
                                    navigate("/job/boost/" + jobDetails?.eid);
                                  }}>
                                  <Link
                                    type={"a"}
                                    className={"dropdown-item"}
                                    ariaControls={"navbarSupportedContent"}
                                    ariaExpanded={"false"}
                                    ariaLabel={"Toggle navigation"}
                                    ariaCurrent={"page"}
                                    text={"Referral Boost"}
                                  />
                                </li>
                              )}

                              {
                                !isDraftJob(jobDetails) && !isClosedJob(jobDetails)
                                  ? <li
                                    onClick={() => {
                                      if (isFeaturedJob(jobDetails)) {
                                        toast.warn(errors.JOB_ALREADY_FEATURED);
                                      } else {
                                        setShowFeatureJobModal(true)
                                      }
                                    }
                                    }>
                                    <Link
                                      type={"a"}
                                      className={"dropdown-item"}
                                      dataBsToggle={"collapse"}
                                      dataBsTtarget={".navbar-collapse.show"}
                                      ariaControls={"navbarSupportedContent"}
                                      ariaExpanded={"false"}
                                      ariaLabel={"Toggle navigation"}
                                      ariaCurrent={"page"}
                                      text={"Feature Job"}
                                    />
                                  </li>
                                  : null
                              }

                              <li
                                onClick={() => {
                                  reNewJob();
                                }}>
                                <Link
                                  type={"a"}
                                  className={"dropdown-item"}
                                  dataBsToggle={"collapse"}
                                  dataBsTtarget={".navbar-collapse.show"}
                                  ariaControls={"navbarSupportedContent"}
                                  ariaExpanded={"false"}
                                  ariaLabel={"Toggle navigation"}
                                  ariaCurrent={"page"}
                                  text={"Renew Job"}
                                />
                              </li>
                            </>
                          )}
                          {isMyJob(jobDetails) ? (
                            <li
                              onClick={() => {
                                duplicateJob();
                              }}>
                              <Link
                                type={"a"}
                                className={"dropdown-item"}
                                dataBsToggle={"collapse"}
                                dataBsTarget={".navbar-collapse.show"}
                                ariaControls={"navbarSupportedContent"}
                                ariaExpanded={"false"}
                                ariaLabel={"Toggle navigation"}
                                ariaCurrent={"page"}
                                text={"Duplicate Job"}
                              />
                            </li>
                          ) : null}

                          {isDraftJob(jobDetails) ? null : (
                            <>
                              <li
                                onClick={() => {
                                  setShowModalClose(true);
                                }}>
                                <Link
                                  type={"a"}
                                  className={"dropdown-item"}
                                  style={{ color: "#EA3E3E" }}
                                  dataBsToggle={"collapse"}
                                  dataBsTarget={".navbar-collapse.show"}
                                  ariacontrols={"navbarSupportedContent"}
                                  ariaExpanded={"false"}
                                  ariaLabel={"Toggle navigation"}
                                  ariaCurrent={"page"}
                                  text={"Close Job"}
                                />
                              </li>
                            </>
                          )}
                        </>
                      ) : null}

                      {isCandidate() && !isClosedJob(jobDetails) ? (
                        <>
                          <li onClick={saveJob}>
                            <Link
                              type={"a"}
                              className="dropdown-item"
                              dataBsToggle={"collapse"}
                              dataBsTarget={".navbar-collapse.show"}
                              ariaControls={"navbarSupportedContent"}
                              ariaExpanded={"false"}
                              ariaLabel={"Toggle navigation"}
                              ariaCurrent={"page"}
                              text={
                                isSavedJob(jobDetails)
                                  ? "Remove from Favorite"
                                  : "Add to Favorite"
                              }
                            />
                          </li>
                          <li
                            onClick={() => {
                              !isReportedJob(jobDetails) && openReportModal();
                            }}>
                            <Link
                              type={"a"}
                              className={
                                isReportedJob(jobDetails)
                                  ? "dropdown-item fade-btn"
                                  : "dropdown-item"
                              }
                              style={{ color: "#EA3E3E" }}
                              dataBsToggle={"collapse"}
                              dataBsTarget={".navbar-collapse.show"}
                              ariaControls={"navbarSupportedContent"}
                              ariaExpanded={"false"}
                              ariaLabel={"Toggle navigation"}
                              ariaCurrent={"page"}
                              text={
                                isReportedJob(jobDetails)
                                  ? "Reported Job"
                                  : "Report Job"
                              }
                            />
                          </li>
                        </>
                      ) : null}
                    </>
                  ) : isMyJob(jobDetails) ? (
                    <li
                      onClick={() => {
                        duplicateJob();
                      }}>
                      <Link
                        type={"a"}
                        className={"dropdown-item"}
                        dataBsToggle={"collapse"}
                        dataBsTarget={".navbar-collapse.show"}
                        ariaControls={"navbarSupportedContent"}
                        ariaExpanded={"false"}
                        ariaLabel={"Toggle navigation"}
                        ariaCurrent={"page"}
                        text={"Duplicate Job"}
                      />
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>

      </div>

      <div className="second-section">
        <div className="left">
          <div className="bgBorderShadow">
            <div className="work-upper">
              <div className="work_exp">
                <p>Experience</p>
                <p>
                  {getCandidateExperienceText(
                    jobDetails.minimum_experience_required
                  )}
                  -
                  {getCandidateExperienceText(
                    jobDetails.maximum_experience_required
                  )}{" "}
                </p>
              </div>

              <div className="work_job">
                <p>Job Type</p>
                <p>{jobDetails.job_type_name}</p>
              </div>

              <div className="work_loc">
                <p>Work Location</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      keyword,
                      jobDetails.job_locations !== null
                        ? jobDetails.job_locations.split(",").join(", ")
                        : ""
                    ),
                  }}></p>
              </div>

              <div className="work_auth">
                <p>Job Duration</p>
                <p>{jobDetails.job_duration_name}</p>
              </div>
            </div>

            <div className="work-lower">
              <div className="work_exp">
                <p>Industry Domain</p>
                <p>{jobDetails.industry_domain_name}</p>
              </div>

              <div className="job_joining">
                <p>Joining</p>
                <p>{jobDetails.joining_preference_name}</p>
              </div>

              <div className="work_telecommute">
                <p>Work mode</p>
                <p>{jobDetails?.engagement_mode?.name ?? "N/A"}</p>
              </div>
              <div className="work_auth">
                <p>Travel Required</p>
                <p>
                  {jobDetails.is_travel_required === "1"
                    ? "Yes " + "(" + jobDetails.travel_percentage + "%)"
                    : "No"}
                </p>
              </div>
            </div>
          </div>
          <div className="bgBorderShadow">
            <div className="respon">
              <Text type={"h2"} text={"Skills"} />

              {jobDetails.job_required_skills &&
                jobDetails.job_required_skills.length > 0 ? (
                <p>
                  Required Skills <br />{" "}
                </p>
              ) : (
                ""
              )}
              <ul>
                {jobDetails.job_required_skills &&
                  jobDetails.job_required_skills.map((skill, i) => {
                    return skill !== "" ? (
                      <li key={i}>
                        <span className="circle"></span>
                        <p
                          style={{ paddingBottom: "0px" }}
                          dangerouslySetInnerHTML={{
                            __html: highlightText(keyword, skill),
                          }}></p>
                      </li>
                    ) : (
                      ""
                    );
                  })}
              </ul>

              {jobDetails.job_additional_skills &&
                jobDetails.job_additional_skills.length > 0 &&
                jobDetails.job_additional_skills[0] !== "" ? (
                <p>
                  Additional Skills <br />{" "}
                </p>
              ) : (
                ""
              )}
              <ul>
                {jobDetails.job_additional_skills &&
                  jobDetails.job_additional_skills.map((skill, i) => {
                    return skill !== "" ? (
                      <li key={i}>
                        <span className="circle"></span>
                        <p
                          style={{ paddingBottom: "0px" }}
                          dangerouslySetInnerHTML={{
                            __html: highlightText(keyword, skill),
                          }}></p>
                      </li>
                    ) : (
                      ""
                    );
                  })}
              </ul>
            </div>

            <div className="overview">
              <Text type={"h2"} text={"Job Description"} />
              <p
                dangerouslySetInnerHTML={{
                  __html: highlightText(
                    keyword,
                    maskEmailPhone(jobDetails?.description)
                  ),
                }}></p>
            </div>

            <div ref={ref}></div>
          </div>

          <div className="bgBorderShadow">
            <div className="overview">
              <Text type={"h2"} text={"Education"} />
              <p>{valueOrDefault(jobDetails?.education, "N/A")}</p>
            </div>

            <div className="overview">
              <Text type={"h2"} text={"No. of positions"} />
              <p>{valueOrDefault(jobDetails?.total_positions, 1)}</p>
            </div>

            <div className="third-section" style={{ marginBottom: "20px" }}>
              {jobDetails.min_salary && jobDetails.max_salary ? (
                <div className="overview">
                  <Text
                    type={"h2"}
                    text={`Salary Range 
                    ${jobDetails.salary_type_name
                        ? "(" + jobDetails.salary_type_name + ")"
                        : ""
                      }`}
                  />

                  <p>
                    {CURRENCY +
                      " " +
                      commonServices.currencyFormatter(jobDetails.min_salary) +
                      " - " +
                      CURRENCY +
                      " " +
                      commonServices.currencyFormatter(jobDetails.max_salary)}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className="overview">
                <Text type={"h2"} text={"Incentive/Bonus"} />
                <p>{valueOrDefault(jobDetails?.bonus, "N/A")}</p>
              </div>

              {jobDetails.job_benefits &&
                jobDetails.job_benefits.length > 0 &&
                jobDetails.job_benefits[0] !== "" ? (
                <div className="respon">
                  <Text type={"h2"} text={"Benefits and Perks"} />

                  <ul>
                    {jobDetails.job_benefits &&
                      jobDetails.job_benefits.map((job_benefit, i) => {
                        return (
                          <li key={i}>
                            <span className="circle"></span>
                            {job_benefit}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {currentUser &&
            !isDraftJob(jobDetails) &&
            currentUser.id === jobDetails.user_id ? (
            <div className="bgBorderShadow">
             
              <div className="result_section" id="result_section">
                
                <div>
                  <div className="filter_tab ">
                    <div className="list-group" id="list-tab" role="tablist">
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-action active"
                        }
                        id="list-applications-list"
                        dataBsToggle={"list"}
                        aHref="#applications-list"
                        role={"tab"}
                        ariaControls={"list-applications"}
                        text={`Applicants
                        (${jobDetails &&
                            jobDetails.applicants &&
                            jobDetails.applicants.applications
                            ? jobDetails.applicants.applications.length
                            : 0
                          })`}
                      />
                      <Link type={"a"}
                        className={"list-group-item list-group-item-action"}
                        id="list-shortlisted-list"
                        dataBsToggle={"list"}
                        aHref={"#shortlisted-list"}
                        role={"tab"}
                        ariaControls={"shortlisted-list"}
                        text={`Shortlisted 
                        (${jobDetails &&
                            jobDetails.applicants &&
                            jobDetails.applicants.shortlisted
                            ? jobDetails.applicants.shortlisted.length
                            : 0})`}

                      />
                      <Link type={"a"}
                        className={"list-group-item list-group-item-action"}
                        id="list-rejected-list"
                        dataBsToggle={"list"}
                        aHref={"#rejected-list"}
                        role={"tab"}
                        ariaControls={"rejected-list"}
                        text={`Rejected 
                        (${jobDetails &&
                            jobDetails.applicants &&
                            jobDetails.applicants.rejected
                            ? jobDetails.applicants.rejected.length
                            : 0})`}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active common_items"
                        id="applications-list"
                        role="tabpanel"
                        aria-labelledby="list-applications-list">
                           <div className="filter_tab_header" style={{width: "20%",float:"right"}}>
                                <div className="list-group" id="list-tab-1" role="tablist">
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action active" id="applicant_list_table" data-bs-toggle="list" href="#applicant_list_table_content" role="tab" aria-controls="applicant_list_table_content">
                                        
                                        <CustomTooltip text="Table View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action" id="applicant_list_grid" data-bs-toggle="list" href="#applicant_list_grid_content" role="tab" aria-controls="applicant_list_grid_content">
                                        <CustomTooltip text="Grid View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content" id="nav_tabContent_1">
                                <div
                                className="tab-pane fade show active"
                                id="applicant_list_table_content"
                                role="tabpanel"
                                aria-labelledby="applicant_list_table"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.applications.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Total Exp</th>
                                            <th scope="col" colSpan={2}>Skills</th>
                                            <th scope="col">Current Location</th>
                                            {/* <th scope="col">Message to Recruiter</th> */}
                                            <th scope="col">Match %age</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {jobDetails.applicants.applications &&
                                            jobDetails.applicants.applications.map(
                                            (applicant, i) => {
                                                return (
                                                <tr
                                                    key={i}
                                                    className={
                                                    jobDetails?.viewed_candidates.includes(
                                                        applicant.user_id
                                                    )
                                                        ? "viewed-profile results"
                                                        : "results"
                                                    }>
                                                    <td onClick={() => {
                                                    addProfileTracking(
                                                        1,
                                                        0,
                                                        applicant.user_id
                                                    );
                                                    window.open(
                                                        "/workprofile/view/" +
                                                        applicant.user_id +
                                                        "?isApplied=1&job_id=" +
                                                        job_id,
                                                        "_blank"
                                                    );
                                                    }}>
                                                    <div
                                                        style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        alignItems: "center",
                                                        }}>
                                                        {applicant.first_name +
                                                        " " +
                                                        applicant.last_name}{" "}
                                                        {applicant.is_referred_candidate ? (
                                                        <img
                                                            src={referredJobImg}
                                                            alt=""></img>
                                                        ) : null}
                                                    </div>
                                                    </td>
                                                    <td>
                                                    {getCandidateExperienceText(
                                                        applicant.total_experience
                                                    )}
                                                    </td>
                                                    <td colSpan={2}>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                        __html: highlightText(
                                                            keyword,
                                                            textCapitalise(
                                                            applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            )
                                                        ),
                                                        }}
                                                    ></p>
                                                    </td>
                                                    <td className="bold">
                                                    {applicant.location_name}
                                                    </td>
                                                    <td>
                                                    {applicant.matchPercentage.toFixed(2)} %
                                                    </td>
                                                    <td>
                                                    <div className="d-flex gap-3">
                                                        <Link type={"icon"}
                                                        title="View Resume"
                                                        onClick={() => {
                                                            addProfileTracking(
                                                            1,
                                                            0,
                                                            applicant.user_id
                                                            );
                                                            window.open(
                                                            "/workprofile/view/" +
                                                            applicant.user_id +
                                                            "?isApplied=1&job_id=" +
                                                            job_id,
                                                            "_blank"
                                                            );
                                                        }}
                                                        iconType={"eye"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Shortlisted"
                                                        onClick={() => {
                                                            changeApplicantStatus(
                                                            applicant.user_id,
                                                            "shortlisted"
                                                            );
                                                        }}
                                                        iconType={"thumbUp"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Rejected"
                                                        onClick={() => {
                                                            changeApplicantStatus(
                                                            applicant.user_id,
                                                            "rejected"
                                                            );
                                                        }}
                                                        iconType={"thumbDown"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Save Candidate"
                                                        onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                        iconType={"heart"} />
                                                        <Link type={"icon"}
                                                        title="View Similar Candidate"
                                                        onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                        iconType={"similar"} />
                                                    </div>
                                                    </td>
                                                </tr>
                                                );
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text type={"h2"} text={"No applicants found!"} />
                                    </div>
                                    )}
                                </div>
                                <div
                                className="tab-pane fade"
                                id="applicant_list_grid_content"
                                role="tabpanel"
                                aria-labelledby="applicant_list_grid"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.applications.length > 0 ? (
                                    <div className="cards__container">
                                        {jobDetails.applicants.applications &&
                                            jobDetails.applicants.applications.map(
                                            (applicant, key) => {
                                                return (
                                                    <div className="grid-data">
                                                        <div key={key} className="view-similar job__card main-card">
                        
                                                        <div
                                                            className="feature_content hand-hover"
                                                        >
                                                            <div className="candidate-card-head">
                                                                <div className="candidate-card-basic">
                                                                    <p
                                                                    className="paragraph_1 truncate-1"
                                                                    title={textCapitalise(applicant.first_name+" "+applicant.last_name)}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightText(
                                                                        keyword,
                                                                        textCapitalise(applicant.first_name+" "+applicant.last_name)
                                                                        ),
                                                                    }}
                                                                    ></p>
                                                                    <Text
                                                                    text={`Exp: ${getCandidateExperienceText(
                                                                        applicant.total_experience
                                                                    )}`}
                                                                    className="paragraph_3"
                                                                    style={{color: "var(--gray7)"}}
                                                                    ></Text>
                                                                </div>
                                                                <div className="circle-rating">
                                                                    <CircularProgressbar
                                                                    value={getPercentage(applicant.matchPercentage)}
                                                                    text={`${getPercentage(applicant.matchPercentage)}%`}
                                                                    styles={buildStyles({
                                                                        textColor: "#263238",
                                                                        pathColor: getBarColor(
                                                                            getPercentage(applicant.matchPercentage)
                                                                        ),
                                                                        trailColor: "#E3E3E3",
                                                                    })}
                                                                    />
                                                                </div>
                    
                                                            </div>
                        
                                                            <p
                                                            className="paragraph_3 d-flex gap-1"
                                                            style={{
                                                                color: "#4B8BFF",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                            >
                                                            <img
                                                                style={{ height: "12px" }}
                                                                src={mapPin}
                                                                alt=""
                                                            />{" "}
                                                            <span
                                                                className="truncate-1"
                                                                title={"Current Location"}
                                                            >
                                                                {applicant?.location_name
                                                                ? applicant?.location_name
                                                                : "NA"}
                                                            </span>
                                                            </p>
                        
                                                            <p
                                                            className="truncate-2 trunc-desc mt-1"
                                                            style={{
                                                                minHeight: "40px",
                                                                height: "40px",
                                                                maxHeight: "40px",
                                                                fontSize: "var(--p1)",
                                                            }}
                                                            title={
                                                                applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightText(
                                                                keyword,
                                                                textCapitalise(
                                                                    applicant.user_skills !== null
                                                                    ? getSkillsString(applicant.user_skills)
                                                                    : "N/A"
                                                                )
                                                                ),
                                                            }}
                                                            ></p>
                        
                                                            <div className="d-flex justify-content-between mt-2">
                                                            <div
                                                                style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                }}
                                                            >
                                                                <Link type={"icon"}
                                                                    title="View Resume"
                                                                    onClick={() => {
                                                                        addProfileTracking(
                                                                        1,
                                                                        0,
                                                                        applicant.user_id
                                                                        );
                                                                        window.open(
                                                                        "/workprofile/view/" +
                                                                        applicant.user_id +
                                                                        "?isApplied=1&job_id=" +
                                                                        job_id,
                                                                        "_blank"
                                                                        );
                                                                    }}
                                                                    iconType={"eye"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Shortlisted"
                                                                    onClick={() => {
                                                                        changeApplicantStatus(
                                                                        applicant.user_id,
                                                                        "shortlisted"
                                                                        );
                                                                    }}
                                                                    iconType={"thumbUp"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Rejected"
                                                                    onClick={() => {
                                                                        changeApplicantStatus(
                                                                        applicant.user_id,
                                                                        "rejected"
                                                                        );
                                                                    }}
                                                                    iconType={"thumbDown"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Save Candidate"
                                                                    onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                                    iconType={"heart"} 
                                                                />
                                                                <Link type={"icon"}
                                                                    title="View Similar Candidate"
                                                                    onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                                    iconType={"similar"} 
                                                                />
                                                            </div>
                        
                                                            <div
                                                                className="feature_content_tags d-flex"
                                                                style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                minHeight: "30px",
                                                                maxHeight: "30px",
                                                                }}
                                                            >
                        
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
    
                                                        </div>
                                                  </div>
                                                );
                                            }
                                            )}
                                    </div>     
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text type={"h2"} text={"No applicants found!"} />
                                    </div>
                                    )}
                                </div>
                            </div>
                      </div>

                      <div
                        className="tab-pane fade common_items"
                        id="shortlisted-list"
                        role="tabpanel"
                        aria-labelledby="list-shortlisted-list">
                            <div className="filter_tab_header" style={{width: "20%",float:"right"}}>
                                <div className="list-group" id="list-tab-1" role="tablist">
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action active" id="shortlisted_list_table" data-bs-toggle="list" href="#shortlisted_list_table_content" role="tab" aria-controls="shortlisted_list_table_content">
                                        
                                        <CustomTooltip text="Table View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action" id="shortlisted_list_grid" data-bs-toggle="list" href="#shortlisted_list_grid_content" role="tab" aria-controls="shortlisted_list_grid_content">
                                        <CustomTooltip text="Grid View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content" id="nav_tabContent_1">
                                <div
                                className="tab-pane fade show active"
                                id="shortlisted_list_table_content"
                                role="tabpanel"
                                aria-labelledby="shortlisted_list_table"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.shortlisted.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Total Exp</th>
                                            <th scope="col" colSpan={2}>Skills</th>
                                            <th scope="col">Current Location</th>
                                            {/* <th scope="col">Message to Recruiter</th> */}
                                            <th scope="col">Match %age</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {jobDetails.applicants.shortlisted &&
                                            jobDetails.applicants.shortlisted.map(
                                            (applicant, i) => {
                                                return (
                                                <tr
                                                    key={i}
                                                    className={
                                                    jobDetails?.viewed_candidates.includes(
                                                        applicant.user_id
                                                    )
                                                        ? "viewed-profile results"
                                                        : "results"
                                                    }>
                                                    <td onClick={() => {
                                                    addProfileTracking(
                                                        1,
                                                        0,
                                                        applicant.user_id
                                                    );
                                                    window.open(
                                                        "/workprofile/view/" +
                                                        applicant.user_id +
                                                        "?isApplied=1&job_id=" +
                                                        job_id,
                                                        "_blank"
                                                    );
                                                    }}>
                                                    <div
                                                        style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        alignItems: "center",
                                                        }}>
                                                        {applicant.first_name +
                                                        " " +
                                                        applicant.last_name}{" "}
                                                        {applicant.is_referred_candidate ? (
                                                        <img
                                                            src={referredJobImg}
                                                            alt=""></img>
                                                        ) : null}
                                                    </div>{" "}
                                                    </td>
                                                    <td>
                                                    {getCandidateExperienceText(
                                                        applicant.total_experience
                                                    )}
                                                    </td>
                                                    <td colSpan={2}>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                        __html: highlightText(
                                                            keyword,
                                                            textCapitalise(
                                                            applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            )
                                                        ),
                                                        }}
                                                    ></p>
                                                    </td>
                                                    <td className="bold">
                                                    {applicant.location_name}
                                                    </td>
                                                    <td>
                                                    {applicant.matchPercentage.toFixed(2)}
                                                    %
                                                    </td>
                                                    <td>
                                                    <div className={"d-flex gap-3"}>
                                                        <Link type={"icon"}
                                                        title="View Resume"
                                                        onClick={() => {
                                                            addProfileTracking(
                                                            1,
                                                            0,
                                                            applicant.user_id
                                                            );
                                                            window.open(
                                                            "/workprofile/view/" +
                                                            applicant.user_id +
                                                            "?isApplied=1&job_id=" +
                                                            job_id,
                                                            "_blank"
                                                            );
                                                        }}
                                                        iconType={"eye"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Rejected"
                                                        onClick={() =>
                                                            changeApplicantStatus(
                                                            applicant.user_id,
                                                            "rejected"
                                                            )
                                                        }
                                                        iconType={"thumbDown"} />
                                                        <Link type={"icon"}
                                                        title="Save Candidate"
                                                        onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                        iconType={"heart"} />
                                                        <Link type={"icon"}
                                                        title="View Similar Candidate"
                                                        onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                        iconType={"similar"} />
                                                    </div>
                                                    </td>
                                                </tr>
                                                );
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text
                                        type={"h2"}
                                        text={"No shortlisted applicant found!"}
                                        />
                                    </div>
                                    )}
                                </div>
                                <div
                                className="tab-pane fade"
                                id="shortlisted_list_grid_content"
                                role="tabpanel"
                                aria-labelledby="shortlisted_list_grid"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.shortlisted.length > 0 ? (
                                    <div className="cards__container">
                                        {jobDetails.applicants.shortlisted &&
                                            jobDetails.applicants.shortlisted.map(
                                            (applicant, key) => {
                                                return (
                                                    <div className="grid-data">
                                                        <div key={key} className="view-similar job__card main-card">
                        
                                                        <div
                                                            className="feature_content hand-hover"
                                                        >
                                                            <div className="candidate-card-head">
                                                                <div className="candidate-card-basic">
                                                                    <p
                                                                    className="paragraph_1 truncate-1"
                                                                    title={textCapitalise(applicant.first_name+" "+applicant.last_name)}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightText(
                                                                        keyword,
                                                                        textCapitalise(applicant.first_name+" "+applicant.last_name)
                                                                        ),
                                                                    }}
                                                                    ></p>
                                                                    <Text
                                                                    text={`Exp: ${getCandidateExperienceText(
                                                                        applicant.total_experience
                                                                    )}`}
                                                                    className="paragraph_3"
                                                                    style={{color: "var(--gray7)"}}
                                                                    ></Text>
                                                                </div>
                                                                <div className="circle-rating">
                                                                    <CircularProgressbar
                                                                    value={getPercentage(applicant.matchPercentage)}
                                                                    text={`${getPercentage(applicant.matchPercentage)}%`}
                                                                    styles={buildStyles({
                                                                        textColor: "#263238",
                                                                        pathColor: getBarColor(
                                                                            getPercentage(applicant.matchPercentage)
                                                                        ),
                                                                        trailColor: "#E3E3E3",
                                                                    })}
                                                                    />
                                                                </div>
                    
                                                            </div>
                        
                                                            <p
                                                            className="paragraph_3 d-flex gap-1"
                                                            style={{
                                                                color: "#4B8BFF",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                            >
                                                            <img
                                                                style={{ height: "12px" }}
                                                                src={mapPin}
                                                                alt=""
                                                            />{" "}
                                                            <span
                                                                className="truncate-1"
                                                                title={"Current Location"}
                                                            >
                                                                {applicant?.location_name
                                                                ? applicant?.location_name
                                                                : "NA"}
                                                            </span>
                                                            </p>
                        
                                                            <p
                                                            className="truncate-2 trunc-desc mt-1"
                                                            style={{
                                                                minHeight: "40px",
                                                                height: "40px",
                                                                maxHeight: "40px",
                                                                fontSize: "var(--p1)",
                                                            }}
                                                            title={
                                                                applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightText(
                                                                keyword,
                                                                textCapitalise(
                                                                    applicant.user_skills !== null
                                                                    ? getSkillsString(applicant.user_skills)
                                                                    : "N/A"
                                                                )
                                                                ),
                                                            }}
                                                            ></p>
                        
                                                            <div className="d-flex justify-content-between mt-2">
                                                            <div
                                                                style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                }}
                                                            >
                                                                <Link type={"icon"}
                                                                    title="View Resume"
                                                                    onClick={() => {
                                                                        addProfileTracking(
                                                                        1,
                                                                        0,
                                                                        applicant.user_id
                                                                        );
                                                                        window.open(
                                                                        "/workprofile/view/" +
                                                                        applicant.user_id +
                                                                        "?isApplied=1&job_id=" +
                                                                        job_id,
                                                                        "_blank"
                                                                        );
                                                                    }}
                                                                    iconType={"eye"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Rejected"
                                                                    onClick={() => {
                                                                        changeApplicantStatus(
                                                                        applicant.user_id,
                                                                        "rejected"
                                                                        );
                                                                    }}
                                                                    iconType={"thumbDown"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Save Candidate"
                                                                    onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                                    iconType={"heart"} 
                                                                />
                                                                <Link type={"icon"}
                                                                    title="View Similar Candidate"
                                                                    onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                                    iconType={"similar"} 
                                                                />
                                                            </div>
                        
                                                            <div
                                                                className="feature_content_tags d-flex"
                                                                style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                minHeight: "30px",
                                                                maxHeight: "30px",
                                                                }}
                                                            >
                        
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
    
                                                        </div>
                                                  </div>
                                                );
                                            }
                                            )}
                                    </div>     
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text type={"h2"} text={"No shortlisted applicant found!"} />
                                    </div>
                                    )}
                                </div>
                            </div>
                      </div>

                      <div
                        className="tab-pane fade common_items"
                        id="rejected-list"
                        role="tabpanel"
                        aria-labelledby="list-rejected-list">
                            <div className="filter_tab_header" style={{width: "20%",float:"right"}}>
                                <div className="list-group" id="list-tab-1" role="tablist">
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action active" id="rejected_list_table" data-bs-toggle="list" href="#rejected_list_table_content" role="tab" aria-controls="rejected_list_table_content">
                                        
                                        <CustomTooltip text="Table View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                    <a style={{borderBottom: "none"}} className="list-group-item list-group-item-action" id="rejected_list_grid" data-bs-toggle="list" href="#rejected_list_grid_content" role="tab" aria-controls="rejected_list_grid_content">
                                        <CustomTooltip text="Grid View">
                                        <div className='round'>
                                            <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                                        </div>
                                        </CustomTooltip>
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content" id="nav_tabContent_1">
                                <div
                                className="tab-pane fade show active"
                                id="rejected_list_table_content"
                                role="tabpanel"
                                aria-labelledby="rejected_list_table"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.rejected.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Total Exp</th>
                                            <th scope="col">Skills</th>
                                            <th scope="col">Current Location</th>
                                            {/* <th scope="col">Message to Recruiter</th> */}
                                            <th scope="col">Match %age</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {jobDetails.applicants.rejected &&
                                            jobDetails.applicants.rejected.map(
                                            (applicant, i) => {
                                                return (
                                                <tr
                                                    key={i}
                                                    className={
                                                    jobDetails?.viewed_candidates.includes(
                                                        applicant.user_id
                                                    )
                                                        ? "viewed-profile results"
                                                        : "results"
                                                    }>
                                                    <td
                                                    onClick={() => {
                                                        addProfileTracking(
                                                        1,
                                                        0,
                                                        applicant.user_id
                                                        );
                                                        window.open(
                                                        "/workprofile/view/" +
                                                        applicant.user_id +
                                                        "?isApplied=1&job_id=" +
                                                        job_id,
                                                        "_blank"
                                                        );
                                                    }}
                                                    className={
                                                        jobDetails?.viewed_candidates.includes(
                                                        applicant.user_id
                                                        )
                                                        ? "viewed-profile bold"
                                                        : "bold"
                                                    }>
                                                    <div
                                                        style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        alignItems: "center",
                                                        }}>
                                                        {applicant.first_name +
                                                        " " +
                                                        applicant.last_name}{" "}
                                                        {applicant.is_referred_candidate ? (
                                                        <img
                                                            src={referredJobImg}
                                                            alt=""></img>
                                                        ) : null}
                                                    </div>
                                                    </td>
                                                    <td>
                                                    {getCandidateExperienceText(
                                                        applicant.total_experience
                                                    )}
                                                    </td>
                                                    <td>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                        __html: highlightText(
                                                            keyword,
                                                            textCapitalise(
                                                            applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            )
                                                        ),
                                                        }}
                                                    ></p>
                                                    </td>
                                                    <td className="bold">
                                                    {applicant.location_name}
                                                    </td>
                                                    {/* <td onClick={() => {
                                                    addProfileTracking(
                                                        1,
                                                        0,
                                                        applicant.user_id
                                                    );
                                                    window.open(
                                                        "/workprofile/view/" +
                                                        applicant.user_id +
                                                        "?isApplied=1&job_id=" +
                                                        job_id,
                                                        "_blank"
                                                    );
                                                    }}>
                                                    <div
                                                        className="recruiter-message"
                                                        title={applicant.recruiter_msg}>
                                                        {applicant.recruiter_msg !== null
                                                        ? `${applicant.recruiter_msg.substring(
                                                            0,
                                                            50
                                                        )}${applicant.recruiter_msg
                                                            .length > 50
                                                            ? "..."
                                                            : ""
                                                        }`
                                                        : "None"}
                                                    </div>
                                                    </td> */}
                                                    {/* <td ><a  onClick={() => { addProfileTracking(1, 0, applicant.user_id); window.open("/workprofile/view/" + applicant.user_id + "?isApplied=1&job_id=" + job_id, '_blank') }}>View</a></td> */}
                                                    <td>
                                                    {applicant.matchPercentage.toFixed(2)}
                                                    %
                                                    </td>
                                                    <td>
                                                    <div className="d-flex gap-3">
                                                        <Link type={"icon"}
                                                        title="View Resume"
                                                        onClick={() => {
                                                            addProfileTracking(
                                                            1,
                                                            0,
                                                            applicant.user_id
                                                            );
                                                            window.open(
                                                            "/workprofile/view/" +
                                                            applicant.user_id +
                                                            "?isApplied=1&job_id=" +
                                                            job_id,
                                                            "_blank"
                                                            );
                                                        }}
                                                        iconType={"eye"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Shortlisted"
                                                        onClick={() => {
                                                            changeApplicantStatus(
                                                            applicant.user_id,
                                                            "shortlisted"
                                                            );
                                                        }}
                                                        iconType={"thumbUp"}
                                                        />
                                                        <Link type={"icon"}
                                                        title="Save Candidate"
                                                        onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                        iconType={"heart"} />
                                                        <Link type={"icon"}
                                                        title="View Similar Candidate"
                                                        onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                        iconType={"similar"} />
                                                    </div>
                                                    </td>
                                                </tr>
                                                );
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text
                                        type={"h2"}
                                        text={"No rejected applicant found!"}
                                        />
                                    </div>
                                    )}
                                </div>
                                <div
                                className="tab-pane fade"
                                id="rejected_list_grid_content"
                                role="tabpanel"
                                aria-labelledby="rejected_list_grid"
                                >
                                    {jobDetails &&
                                    jobDetails.applicants &&
                                    jobDetails.applicants.rejected.length > 0 ? (
                                    <div className="cards__container">
                                        {jobDetails.applicants.shortlisted &&
                                            jobDetails.applicants.rejected.map(
                                            (applicant, key) => {
                                                return (
                                                    <div className="grid-data">
                                                        <div key={key} className="view-similar job__card main-card">
                        
                                                        <div
                                                            className="feature_content hand-hover"
                                                        >
                                                            <div className="candidate-card-head">
                                                                <div className="candidate-card-basic">
                                                                    <p
                                                                    className="paragraph_1 truncate-1"
                                                                    title={textCapitalise(applicant.first_name+" "+applicant.last_name)}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightText(
                                                                        keyword,
                                                                        textCapitalise(applicant.first_name+" "+applicant.last_name)
                                                                        ),
                                                                    }}
                                                                    ></p>
                                                                    <Text
                                                                    text={`Exp: ${getCandidateExperienceText(
                                                                        applicant.total_experience
                                                                    )}`}
                                                                    className="paragraph_3"
                                                                    style={{color: "var(--gray7)"}}
                                                                    ></Text>
                                                                </div>
                                                                <div className="circle-rating">
                                                                    <CircularProgressbar
                                                                    value={getPercentage(applicant.matchPercentage)}
                                                                    text={`${getPercentage(applicant.matchPercentage)}%`}
                                                                    styles={buildStyles({
                                                                        textColor: "#263238",
                                                                        pathColor: getBarColor(
                                                                            getPercentage(applicant.matchPercentage)
                                                                        ),
                                                                        trailColor: "#E3E3E3",
                                                                    })}
                                                                    />
                                                                </div>
                    
                                                            </div>
                        
                                                            <p
                                                            className="paragraph_3 d-flex gap-1"
                                                            style={{
                                                                color: "#4B8BFF",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                            >
                                                            <img
                                                                style={{ height: "12px" }}
                                                                src={mapPin}
                                                                alt=""
                                                            />{" "}
                                                            <span
                                                                className="truncate-1"
                                                                title={"Current Location"}
                                                            >
                                                                {applicant?.location_name
                                                                ? applicant?.location_name
                                                                : "NA"}
                                                            </span>
                                                            </p>
                        
                                                            <p
                                                            className="truncate-2 trunc-desc mt-1"
                                                            style={{
                                                                minHeight: "40px",
                                                                height: "40px",
                                                                maxHeight: "40px",
                                                                fontSize: "var(--p1)",
                                                            }}
                                                            title={
                                                                applicant.user_skills !== null
                                                                ? getSkillsString(applicant.user_skills)
                                                                : "N/A"
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightText(
                                                                keyword,
                                                                textCapitalise(
                                                                    applicant.user_skills !== null
                                                                    ? getSkillsString(applicant.user_skills)
                                                                    : "N/A"
                                                                )
                                                                ),
                                                            }}
                                                            ></p>
                        
                                                            <div className="d-flex justify-content-between mt-2">
                                                            <div
                                                                style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                }}
                                                            >
                                                                <Link type={"icon"}
                                                                    title="View Resume"
                                                                    onClick={() => {
                                                                        addProfileTracking(
                                                                        1,
                                                                        0,
                                                                        applicant.user_id
                                                                        );
                                                                        window.open(
                                                                        "/workprofile/view/" +
                                                                        applicant.user_id +
                                                                        "?isApplied=1&job_id=" +
                                                                        job_id,
                                                                        "_blank"
                                                                        );
                                                                    }}
                                                                    iconType={"eye"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Shortlisted"
                                                                    onClick={() => {
                                                                        changeApplicantStatus(
                                                                        applicant.user_id,
                                                                        "shortlisted"
                                                                        );
                                                                    }}
                                                                    iconType={"thumbUp"}
                                                                />
                                                                <Link type={"icon"}
                                                                    title="Save Candidate"
                                                                    onClick={() => addCandidateToSaveLater(applicant.candidate_id, setLoading)}
                                                                    iconType={"heart"} 
                                                                />
                                                                <Link type={"icon"}
                                                                    title="View Similar Candidate"
                                                                    onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                                    iconType={"similar"} 
                                                                />
                                                            </div>
                        
                                                            <div
                                                                className="feature_content_tags d-flex"
                                                                style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                minHeight: "30px",
                                                                maxHeight: "30px",
                                                                }}
                                                            >
                        
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
    
                                                        </div>
                                                  </div>
                                                );
                                            }
                                            )}
                                    </div>     
                                    ) : (
                                    <div className="no_jobs">
                                        <img
                                        onError={commonServices.imgError}
                                        src={emptyResult}
                                        alt=""
                                        />
                                        <Text type={"h2"} text={"No rejected applicant found!"} />
                                    </div>
                                    )}
                                </div>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* <div className="right mb-2"> */}

        <div className="right mb-2">
          <div className="about-company mb-3">
            <Text type={"h2"} text={"About the company"} />
            <p>
              <a
                className="hand-hover about-company-name green-text"
                href={undefined}
                onClick={() =>
                  window.open(
                    "/company-profile/" + jobDetails?.company_details?.eid,
                    "_blank"
                  )
                }
                target="_blank">
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(keyword, jobDetails.company_name),
                  }}></span>
                <i
                  class="bi bi-box-arrow-up-right"
                  style={{ marginLeft: "10px" }}></i>
              </a>
            </p>
            <br />
            <p className="para_2">
              {" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightText(
                    keyword,
                    jobDetails?.company_description
                  ),
                }}></span>{" "}
            </p>
            <br />
            <br />
            <p className="para_3">
              Website:{" "}
              <a
                target="_blank"
                href={jobDetails.company_website}
                className="green-txt comp-website">
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(keyword, jobDetails.company_website),
                  }}></span>
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="apply_page">
      {loading ? (
        <Loader />
      ) : null}
    </div>
  );
}

export default JobDetailsLayouts;